@import 'base';

.hero-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .hero-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;

    .hero-title-before {
      font-weight: normal;
      text-align: left;
      width: 100%;
    }

    .hero-title {
      font-size: 3rem;
      font-weight: 100;
      line-height: 1.2;
      display: inline-block;
      text-align: center;
      text-transform: capitalize;
      font-family: "Marck Script", "SF Pro Display", "SF Mono", $default-font-families;

      @include respond-above(320px) {
        font-size: 4rem;
      }

      @include respond-above(sm) {
        font-size: 6rem;
      }

      @include respond-above(md) {
        font-size: 8rem;
      }

      @include respond-below(1400px) {
        width: min-content;
      }
    }

    .hero-title-after {
      font-weight: normal;
      text-align: right;
      width: 100%;
    }
  }

  .hero-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .name {
      margin: $theme--spacing * 2;
      text-align: left;
    }

    .copyright {
      margin: $theme--spacing * 2;
      text-align: right;
    }
  }
}

.about-root {
  padding: 3 * $theme--spacing 0 * $theme--spacing;
  display: flex;
  flex-direction: column;
  // height: 100%;

  .about-head {
    padding: 0 * $theme--spacing 3 * $theme--spacing;

    @include respond-below(sm) {
      padding: 0 * $theme--spacing 2 * $theme--spacing;
    }

    .about-title {}

    .about-subtitle {
      color: $primary-color-light
    }
  }

  .about-content {
    padding: 0 * $theme--spacing 3 * $theme--spacing;
    flex: 1 1 0;
    min-height: 0%;

    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .about-terminal {
      padding: 0px;
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      min-height: 0%;

      @include respond-above(lg) {
        max-height: 75%;
      }

      .title-bar {
        background-image: linear-gradient(to bottom, rgb(72, 73, 75), rgb(60, 60, 64));
        text-align: center;
        color: rgb(191, 192, 194);
        padding: 4px 0px;
        height: 30px;
        font-size: 14px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: solid 1px rgb(131, 132, 134);
        border-bottom-color: rgb(24, 24, 24);
      }

      .body {
        overflow-x: auto;
        padding: 30px 20px;
        background-color: rgb(30, 30, 30);
        color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border: solid 1px rgb(75, 75, 75);
        border-top: none;
        font-family: "SF Mono", 'Courier New', Courier, monospace, $default-font-families;
        flex: 1 1 0;
        min-height: 0%;

        dl {
          margin: 0px;
          line-height: 21px;
        }

        dl dd {
          margin: 0;
          color: $text-on-primary;
        }

        dl dd:before {
          content: ">>>";
          margin-right: 5px;
        }

        dl dt {
          margin-left: 30px;
          margin-bottom: 30px;
          color: $ios-systemgray3;
          white-space: pre-wrap;
        }

        dl dt:last-child {
          margin-bottom: 0px;
        }

        dl dt:before {
          content: "=>";
          margin-right: 5px;
        }

        a {
          color: $secondary-color-light;
          text-decoration: dotted;
        }
      }
    }
  }
}

.skills-root {
  display: flex;
  flex-direction: column;
  padding: 3 * $theme--spacing 0 * $theme--spacing;

  @include respond-below(sm) {
    padding: 2 * $theme--spacing 0 * $theme--spacing;
  }

  .skills-head {
    padding: 0 * $theme--spacing 3 * $theme--spacing;

    @include respond-below(sm) {
      padding: 0 * $theme--spacing 2 * $theme--spacing;
    }

    .skills-title {}

    .skills-subtitle {
      color: $primary-color-light
    }
  }

  .skills-content {
    flex: 1 0 0;
    min-height: 0%;
    display: flex;
    justify-content: center;
    padding: 0 * $theme--spacing 3 * $theme--spacing;

    @include respond-below(sm) {
      padding: 0 * $theme--spacing 2 * $theme--spacing;
    }

    .skills-catogeries-root {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: flex-start;
    }
  }
}

.contact-root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .contact-container {
    flex: 0 1 auto;
    min-height: 0%;
    padding: 2 * $theme--spacing;

    @include respond-above(sm) {
      padding: 3 * $theme--spacing;
    }

    .title-container {
      margin-bottom: 40px;

      .title {
        @include respond-below(sm) {
          font-size: 36px;
        }
      }

      .subtitle {
        color: $primary-color-light;

        @include respond-below(sm) {
          font-size: 16px;
        }
      }
    }

    .social-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .social-card {
        width: 150px;
        flex: 0 0 auto;
        margin: $theme--spacing * 2;
        border-radius: 50vh;
        display: flex;

        .social-card-action {
          padding: $theme--spacing * 2;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          img {
            max-width: 24px;
            max-height: 24px;
            object-fit: contain;
            object-position: center;
          }

          span {
            margin-left: $theme--spacing * 1.5;
          }

          .MuiCardActionArea-focusHighlight,
          .MuiTouchRipple-root {
            margin: 0px;
          }
        }
      }
    }
  }
}

.projects-list-root {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3 * $theme--spacing 0 * $theme--spacing;

  @include respond-below(sm) {
    padding: 2 * $theme--spacing 0 * $theme--spacing;
  }

  .projects-list-section {
    flex: 1 0 95%;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    padding: 0 * $theme--spacing;

    .projects-section-root {
      display: flex;
      flex-direction: column;
      height: 100%;

      .section-head {
        padding: 0 * $theme--spacing 2 * $theme--spacing;

        @include respond-below(sm) {
          padding: 0 * $theme--spacing 1 * $theme--spacing;
        }

        .section-title {}

        .section-subtitle {
          color: $primary-color-light
        }
      }

      .section-content {
        flex: 1 0 0;
        min-height: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .section-repos {
          padding: 24px;
          display: flex;
          flex-direction: column;
          overflow-x: auto;
          height: 928px;
          flex-wrap: wrap;
          align-content: flex-start;
        }
      }
    }
  }

  .featured {
    padding-bottom: 1.5 * $theme--spacing;
    padding-top: 3 * $theme--spacing;

    @include respond-below(sm) {
      padding-bottom: 1 * $theme--spacing;
      padding-top: 2 * $theme--spacing;
    }
  }

  .other {
    padding-top: 1.5 * $theme--spacing;
    padding-bottom: 3 * $theme--spacing;

    @include respond-below(sm) {
      padding-top: 1 * $theme--spacing;
      padding-bottom: 2 * $theme--spacing;
    }
  }
}
