@import 'base';
@import 'components';
@import 'sections';

html,
body {
  margin: 0;
  padding: 0;
  font-family: $default-font-families;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  background: $page-background;
  background-repeat: no-repeat;
  background-attachment: fixed;
  box-sizing: border-box;
}

.app {
  .section {
    box-sizing: border-box;
    width: 100%;
    padding-top: 32px;
    border-bottom: 2px dashed $ios-systemgray4;
  }

  #hero {
    padding-top: 64px;
    height: 100vh;
    border-bottom: none;
  }
}
