@import 'base';

.navbar {
  height: 64px;

  .MuiToolbar-root {
    height: 64px;
  }

  .navbar-grow {
    flex-grow: 1;
  }

  .navbar-menu-button {
    margin-right: $theme--spacing * 2;
    color: inherit;
  }

  .navbar-title {}

  .navbar-title-large {
    display: block;

    @include respond-below(360px) {
      display: none;
    }
  }

  .navbar-title-small {
    display: none;

    @include respond-below(360px) {
      display: block;
    }
  }

  .navbar-section-desktop {
    display: none;

    @include respond-above(md) {
      display: flex;
    }

    .navbar-button {
      color: $text-on-primary;

      .MuiButton-label {
        text-transform: capitalize;
      }
    }
  }

  .navbar-section-mobile {
    display: flex;

    @include respond-above(md) {
      display: none;
    }
  }
}

.error-root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error {
    display: inline-block;
    text-align: center;

    .title {}

    .technical-message {
      font-family: "SF Mono", 'Courier New', Courier, monospace, $default-font-families;
      color: $primary-color-light;

      a {
        color: $primary-color-light;
        text-decoration: underline;
      }
    }

    .error-message {
      font-family: "SF Mono", 'Courier New', Courier, monospace, $default-font-families;
      color: $primary-color-light;
    }
  }
}

.loading-root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loading {
    display: inline-block;
    text-align: center;

    .spinner {
      margin-bottom: $theme--spacing * 2.5;
    }

    .technical-message {
      font-family: "SF Mono", 'Courier New', Courier, monospace, $default-font-families;
      color: $primary-color-light;

      a {
        color: $primary-color-light;
        text-decoration: underline;
      }
    }
  }
}

.category-root {
  width: 500px;
  // min-height: 256px;
  margin: 2 * $theme--spacing;
  display: block;
  flex-shrink: 1;

  @include respond-below(540px) {
    width: calc(100vw - 76px);
  }

  .category-card {
    width: 100%;
    height: 100%;
    border-radius: 2 * $theme--spacing;
    display: flex;
    flex-direction: column;
    padding: 3 * $theme--spacing 2 * $theme--spacing;

    @include respond-below(sm) {
      padding: 2 * $theme--spacing 1 * $theme--spacing;
      ;
    }

    .card-head {
      padding: 0 * $theme--spacing 1 * $theme--spacing;
    }

    hr {
      margin: 0.5 * $theme--spacing 0 * $theme--spacing;
    }

    .card-content {
      padding: 1 * $theme--spacing 1 * $theme--spacing 0 * $theme--spacing 1 * $theme--spacing;
      flex: 1 0 0;
      min-height: 0%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-content: flex-start;

      .skill-item {
        background-color: $ios-systemgray5;
        padding: $theme--spacing 2 * $theme--spacing;
        margin-right: $theme--spacing;
        margin-bottom: $theme--spacing;
        min-width: 70px;
        text-align: center;
        border-radius: 50vh;
        display: inline;
        font-size: 18px;
        color: $primary-color-light;
        font-weight: bold;
      }
    }
  }
}

.repo-card-root {
  height: 400px;
  width: 300px;
  margin: 2 * $theme--spacing;
  display: block;
  flex-shrink: 1;

  @include respond-below(340px) {
    width: calc(100vw - 76px);
  }

  .repo-card {
    width: 100%;
    height: 100%;
    border-radius: 2 * $theme--spacing;
    padding: 0px;
    display: flex;

    .content {
      flex: 1 1 auto;
      min-width: 0;
      padding: 3 * $theme--spacing;
      padding-right: 2 * $theme--spacing;
      display: flex;
      flex-direction: column;

      @include respond-below(sm) {
        padding: 2 * $theme--spacing;
        padding-right: 1 * $theme--spacing;
      }

      .content-header {
        display: flex;
        align-items: center;
        justify-items: center;
        margin-bottom: 1.5 * $theme--spacing;

        .icon-root {
          margin-right: 0.6 * $theme--spacing;
          display: flex;
          align-items: baseline;

          svg {
            height: 16px;
            width: 16px;
            color: $primary-color-light;
          }
        }

        .title-root {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          a {
            text-decoration: none;

            .title {
              line-height: initial;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .subtitle {
            color: $primary-color-light;

            a {
              text-decoration: none;
            }
          }
        }
      }

      .description {
        margin-bottom: 1 * $theme--spacing;
        flex: 1 1 auto;
        min-height: 0%;

        word-wrap: break-word;
        white-space: pre-line;
        overflow: hidden;

        @include respond-above(450px) {
          overflow-y: auto;
        }

        .description-body {

          a {
            text-decoration: none;

            .description-body-heading {
              text-decoration: underline dotted $primary-color-light 1px;
              line-height: inherit;
              color: $primary-color-light;
              font-family: "SF Mono", 'Courier New', Courier, monospace, $default-font-families;
            }
          }


          .description-readme {
            h1 {
              font-size: 20px;
              margin: $theme--spacing 0px;
            }

            h2 {
              font-size: 19px;
              margin: 0.9 * $theme--spacing 0px;
            }

            h3 {
              font-size: 18px;
              margin: 0.8 * $theme--spacing 0px;
            }

            p {
              font-size: 16px;
              margin: 0.6 * $theme--spacing 0px;
            }

            ul {
              margin: 0px;
            }
          }
        }
      }

      .topics {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-items: center;

        a {
          text-decoration: none;
        }

        .topic {
          display: inline;
          padding: 0.2 * $theme--spacing 1 * $theme--spacing;
          background-color: rgba($secondary-color-light, 0.15);
          border-radius: 50vh;
          margin-right: $theme--spacing;
          margin-bottom: 1 * $theme--spacing;
        }
      }

      .content-footer {
        display: flex;

        .left {
          @include respond-below(340px) {
            flex: 1 0 auto;
          }

          .footer-buttons {

            .github-star-icon,
            .github-fork-icon {
              height: 16px;
              width: 16px;
            }

            .icon-text {
              margin-left: 0.5 * $theme--spacing;
              margin-right: $theme--spacing;
            }
          }
        }

        .center {
          flex: 1 0 auto;
          display: flex;

          @include respond-below(340px) {
            display: none;
          }

          .archived-text {
            display: inline;
            padding: 0.2 * $theme--spacing 1 * $theme--spacing;
            background-color: $ios-systemgray5;
            border-radius: 50vh;
            margin: auto;

            a {
              text-decoration: none;
            }
          }

          .license-button {
            display: flex;
            padding: 0.2 * $theme--spacing 1 * $theme--spacing;
            background-color: rgba($primary-color-light, 0.15);
            border-radius: 50vh;
            margin: auto;

            .license-icon {
              color: $primary-color;
              height: 16px;
              width: 16px;
              margin-right: $theme--spacing;
            }

            .license-text {
              color: $primary-color;
              text-transform: none;
            }
          }
        }

        .right {
          display: flex;
          justify-items: center;
          align-items: center;

          .footer-buttons {
            margin-left: $theme--spacing;

            .github-icon {}

            .link-icon {
              color: $primary-color-light;
            }
          }
        }
      }

    }
  }
}

.languages-root {
  display: flex;
  flex-direction: column;

  .language-before {
      width: 5px;
      height: 2 * $theme--spacing;
      background-color: white;
  }

  .language-after {
      width: 5px;
      height: 2 * $theme--spacing;
      background-color: red;
  }

  .languages {
      width: 5px;
      flex: 1 0 auto;
  }
}
